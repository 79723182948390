import React from "react";
import Layout from "../parts/Layout";
import { withPrefix, Link } from "gatsby";
import { PageComponentProps } from "../../types/types";

// @ts-ignore
import DnAndSnDn from "../../images/svg-dn/Clairnote-DN-and-Clairnote-SN.svg";
// @ts-ignore
import DnAndSnSn from "../../images/svg-sn/Clairnote-DN-and-Clairnote-SN.svg";

import * as pageStyles from "../global-styles/page.module.css";

export const ClairnoteDnClairnoteSn = (props: PageComponentProps) => {
  const { clairnoteName, otherClairnoteName, dn, otherUrlDir } = props;
  const title = `${clairnoteName} and ${otherClairnoteName}`;
  const description = `Discussion and illustrations of the two variants of Clairnote music notation: ${clairnoteName} and ${otherClairnoteName}.`;
  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <h2 className={"title is-6"} style={{ textAlign: "center" }}>
          <Link
            className="button"
            style={{ textDecoration: "none" }}
            // Remove "/dn/" or "/" (4 or 1) from the front of pathname.
            to={otherUrlDir + props.location.pathname.slice(dn ? 4 : 1)}
          >
            Switch to the {otherClairnoteName} Version of this Website
          </Link>
        </h2>
        <div>
          <p>
            There are two different "flavors" or variants of Clairnote music
            notation:
          </p>
          <ul>
            <li>Clairnote SN (Standard Noteheads)</li>
            <li>
              Clairnote DN (Dual Noteheads), the original variant, originally
              known as simply "Clairnote"
            </li>
          </ul>
          <p>
            The differences between Clairnote DN and SN are illustrated in the
            following comparison:
          </p>
          <div className={pageStyles.imageContainer}>
            <img
              className="alignnone"
              src={dn ? DnAndSnDn : DnAndSnSn}
              alt="Illustration of Clairnote DN and Clairnote SN music notation systems"
            />
          </div>
          <p>
            Basically, Clairnote DN uses hollow and solid noteheads for pitch,
            while Clairnote SN does not. Clairnote DN gives half notes double
            stems to differentiate them from quarter notes — its only difference
            from standard music notation's rhythm notation system. Clairnote SN
            uses the standard rhythm notation system without modification, with
            hollow noteheads for half notes and whole notes, and solid noteheads
            for quarter notes, eighth notes, etc.
          </p>
          <p>
            Clairnote DN has a highly visible 6-6 pattern of alternating solid
            and hollow noteheads. Clairnote SN has a more subtle 6-6 pattern of
            alternating line notes and space notes, and uses ledger lines to
            provide this regularly alternating pattern. In Clairnote DN the
            differences between pitches (and between intervals) are more
            obvious.
          </p>
          <h2 className="title is-5">Why Use Clairnote SN?</h2>
          <p>
            Clairnote SN has more in common with standard music notation since
            they use the same rhythm symbols. This makes it easier to learn both
            of these systems at the same time and to use them interchangeably,
            switching back and forth between them "bilingually". So Clairnote SN
            may make more sense for musicians who already know standard notation
            well, or who want to learn to read standard notation at a high level
            of proficiency.
          </p>
          <h2 className="title is-5">Why Use Clairnote DN?</h2>
          <p>
            Clairnote DN offers clearer and more obvious distinctions between
            pitches and intervals, by using solid and hollow notes for pitch.
            This may be a better use of the highly visible difference between
            hollow and solid noteheads, especially if learning standard notation
            "bilingually" is not a goal. And even if it is, some find they can
            switch between Clairnote DN and the standard system just fine
            without any real difficulty.
          </p>
          <p>
            Musicians should consider their own goals, particularly whether they
            include becoming a proficient reader of standard notation. Some may
            prefer Clairnote DN and others may prefer Clairnote SN. Both offer
            similar advantages over standard music notation.
          </p>
          <h2 className="title is-5">
            Clairnote DN and Clairnote SN on this Website
          </h2>
          <p>
            Most pages on this site have both Clairnote DN and Clairnote SN
            versions. You can think of the site as two parallel sites, one for
            each system. You can switch between the two versions of the site by
            clicking the "Switch to..." button at the top of this page or
            clicking the "SN ➞ DN" (or "DN ➞ SN") link in the footer at the
            bottom of every page.
          </p>
          <p>
            See also the blog posts{" "}
            <Link to={withPrefix("blog/2018/04/introducing-clairnote-sn/")}>
              Introducing Clairnote SN
            </Link>{" "}
            and{" "}
            <Link to={withPrefix("blog/2018/12/defaulting-to-clairnote-sn/")}>
              Defaulting to Clairnote SN
            </Link>
            .
          </p>
        </div>
      </article>
    </Layout>
  );
};
