import { PageProps } from "gatsby";
import React from "react";

import { ClairnoteDnClairnoteSn } from "../components/pages/ClairnoteDnClairnoteSn";

import { snProps } from "../js/utils";

const ClairnoteDnClairnoteSnPage = (props: PageProps) => (
  <ClairnoteDnClairnoteSn {...{ ...snProps, ...props }} />
);

export default ClairnoteDnClairnoteSnPage;
